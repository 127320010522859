import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/Layout";
import PageBanner from "../components/PageBanner";
import CallToAction from "../components/CallToAction";
import MenuList from "../components/PizzaMenus/MenuList";
import { data } from "../data";

const Specials = () => {
  return (
    <Layout title="Tasty Pizza – Specials">
      <PageBanner
        title={data.specials.pageHeading}
        image={data.specials.image}
      />
      <div class="favorites-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 col-md-7">
              <div class="favorites-item-warp">
                {data.specials &&
                  data.specials.specialsItems.map((item) => {
                    return <MenuList menu={item} />;
                  })}
              </div>
            </div>
            <div class="col-lg-5 col-md-5">
              <div class="favorite-right">
                <div class="favorite-thumb">
                  <StaticImage
                    src="https://res.cloudinary.com/dzoanfblg/image/upload/v1623704895/Tasty%20Pizza/garlic2_yd2xzt.jpg"
                    alt="Tasty Pizzza - Specials"
                  />
                </div>
                <div class="favorite-thumb">
                  <StaticImage
                    src="https://res.cloudinary.com/dzoanfblg/image/upload/v1623731286/Tasty%20Pizza/P11_lrjkeb.jpg"
                    alt="Tasty Pizza – Specials"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Specials;
