import React from "react";
import HtmlToReactParser from "html-to-react";

const htmlParser = HtmlToReactParser.Parser;

const PageBanner = ({ title, image }) => {
  const htmlToReactParser = new htmlParser();
  return (
    <div
      className="hero-slide-item"
      style={{ backgroundImage: "url(" + image + ")" }}
    >
      <div className="hero-slide-item">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="hero-text">
                <h1>{htmlToReactParser.parse(title)}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageBanner;
