import { Link } from "gatsby";
import React from "react";

const CallToAction = ({ title, description, linkText, linkUrl }) => {
  return (
    <div className="easy-find">
      <h4>{title}</h4>
      <p>{description}</p>
      <Link className="common-btn" to={linkUrl}>
        {linkText}
      </Link>
    </div>
  );
};

export default CallToAction;
