import React from "react";

const MenuList = ({ menu }) => {
  return (
    <div className="favorite-item-box">
      <h1>{menu.title}</h1>
      {menu.description && <p>{menu.description}</p>}
      <div className="fvrt-item-list">
        {menu.items &&
          menu.items.map((item, index) => {
            return (
              <p key={index}>
                {item.isSpace ? <br /> : undefined}
                {item.description}{" "}
                {item.price && <span className="txt-red">${item.price}</span>}
                {item.extraItem && (
                  <>
                    {" "}
                    Extra {item.extraItem} for{" "}
                    <span className="txt-red">${item.extraItemPrice}</span>
                  </>
                )}
              </p>
            );
          })}

        {/* <p>
          Add Chicken, Hamburger, or Bacon for{" "}
          <span className="txt-red">$3.50</span>
          Extra Cheese for <span className="txt-red">$3.50</span>
        </p> */}
      </div>
    </div>
  );
};

export default MenuList;
